import * as API from '@/api/index'

 export default {
     // 收入类别列表
     all: params => {
         return API.POST('api/additionalIncomeCategory/all', params)
     },
     //新增类别
     create: params => {
         return API.POST('api/additionalIncomeCategory/create', params)
     },
     //编辑类别
     update:params => {
         return API.POST('api/additionalIncomeCategory/update', params)
     },
     //类别顺序调整
     move:params => {
         return API.POST('api/additionalIncomeCategory/move', params)
     },
 
 }